<template>
  <div ref="rootDiv" class="statsRoot" style="position: relative">
    <transition :name="transitionType" @enter="onEnter">
      <div v-if="view == 'root'">
        <v-row>
          <v-col cols="12">
            <tileCard :aspect-ratio="0">
              <template v-slot:content>
                <div class="d-flex justify-center">
                  <div>
                    <h2>Statistics</h2>
                    <small>Press a tile for more information</small>
                  </div>
                </div>
              </template>
            </tileCard>
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              :progress="dayPercent"
              :size="0.4"
              :color="
                dayPercent < 90
                  ? 'success'
                  : dayPercent < 100
                  ? 'warning'
                  : 'error'
              "
              :progressText="`${dayPercent}%`"
              v-ripple
              @click="goTo('days')"
              :small="$store.getters.windowSize.width <= 490"
              >Days Passed</tileCard
            >
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              :progress="tagPercent"
              :size="0.4"
              :color="tagStatusColor"
              :progressText="`${tagPercent}%`"
              v-ripple
              @click="goTo('tags')"
              :small="$store.getters.windowSize.width <= 490"
              >Tags Scanned</tileCard
            >
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              :progress="consistencyPercent"
              :size="0.4"
              :color="
                consistencyPercent < 75
                  ? 'error'
                  : consistencyPercent < 95
                  ? 'warning'
                  : 'success'
              "
              :progressText="`${consistencyPercent}%`"
              v-ripple
              @click="goTo('consistency')"
              :small="$store.getters.windowSize.width <= 490"
              >Scanning Consistency</tileCard
            >
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              v-ripple
              @click="goTo('timing')"
              :content="mostRecentScan"
              :color="mostRecentScanColor"
              :small="$store.getters.windowSize.width <= 490"
              >Last Scan</tileCard
            >
          </v-col>
        </v-row>
      </div>
    </transition>

    <transition :name="transitionType" @enter="onEnter">
      <div v-if="view == 'days'">
        <v-row>
          <v-col cols="12">
            <tileCard :aspect-ratio="0" @click="goTo('root')">
              <template v-slot:content>
                <div class="d-flex justify-center">
                  <v-btn @click="goTo('root')" icon>
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-spacer />
                  <div>
                    <h2>Timing Information</h2>
                  </div>
                  <v-spacer />
                  <v-btn style="opacity: 0" icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </template>
            </tileCard>
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              noClick
              :content="startDate"
              :small="$store.getters.windowSize.width <= 490"
              color="info"
              >Start Date</tileCard
            >
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              noClick
              :content="endDate"
              :small="$store.getters.windowSize.width <= 490"
              color="info"
              >End Date</tileCard
            >
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              noClick
              :content="daysPassed"
              :small="$store.getters.windowSize.width <= 490"
              :color="
                dayPercent < 90
                  ? 'success'
                  : dayPercent < 100
                  ? 'warning'
                  : 'error'
              "
              >Days Elapsed
              <!-- <template v-slot:content>{{
                daysPassed
              }}</template> -->
            </tileCard>
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              noClick
              :content="daysRemaining >= 0 ? daysRemaining : 0"
              :small="$store.getters.windowSize.width <= 490"
              :color="
                dayPercent < 90
                  ? 'success'
                  : dayPercent < 100
                  ? 'warning'
                  : 'error'
              "
              >Days Remaining</tileCard
            >
          </v-col>
        </v-row>
      </div>
    </transition>

    <transition :name="transitionType" @enter="onEnter">
      <div v-if="view == 'tags'">
        <v-row>
          <v-col cols="12">
            <tileCard :aspect-ratio="0" @click="goTo('root')">
              <template v-slot:content>
                <div class="d-flex justify-center">
                  <v-btn @click="goTo('root')" icon>
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-spacer />
                  <div>
                    <h2>Scan Information</h2>
                  </div>
                  <v-spacer />
                  <v-btn style="opacity: 0" icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </template>
            </tileCard>
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              noClick
              :small="$store.getters.windowSize.width <= 490"
              :content="totalTags"
              color="info"
              >Total Tag{{ totalTags != 1 ? "s" : " Count" }}</tileCard
            >
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              noClick
              :small="$store.getters.windowSize.width <= 490"
              :content="tagsScanned"
              :color="tagStatusColor"
              >Tag{{ tagsScanned != 1 ? "s" : "" }} Scanned</tileCard
            >
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              noClick
              :small="$store.getters.windowSize.width <= 490"
              :content="tagsRemaining"
              :color="tagStatusColor"
              >Tag{{ tagsRemaining != 1 ? "s" : "" }} Left</tileCard
            >
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              noClick
              :small="$store.getters.windowSize.width <= 490"
              :content="tagStatusCount ? -tagStatusCount : undefined"
              :color="tagStatusColor"
              :icon="tagStatusCount ? '' : 'mdi-check-circle-outline'"
              >{{
                tagStatusCount
                  ? `Tag${tagStatusCount != -1 ? "s" : ""} Behind`
                  : "On Track"
              }}</tileCard
            >
          </v-col>
        </v-row>
      </div>
    </transition>

    <transition :name="transitionType" @enter="onEnter">
      <div v-if="view == 'consistency'">
        <v-row>
          <v-col cols="12">
            <tileCard :aspect-ratio="0" @click="goTo('root')">
              <template v-slot:content>
                <div class="d-flex justify-center">
                  <v-btn @click="goTo('root')" icon>
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-spacer />
                  <div>
                    <h2>Consistency</h2>
                  </div>
                  <v-spacer />
                  <v-btn style="opacity: 0" icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </template>
            </tileCard>
          </v-col>
          <v-col cols="12">
            <tileCard noClick :aspect-ratio="0">
              <template v-slot:content>
                <div>
                  <v-row>
                    <v-col>
                      <v-date-picker
                        :events="consistencyDatesFunction"
                        class="ma-3 smallDatePicker"
                        style="max-width: 600px"
                        elevation="5"
                      />
                    </v-col>

                    <v-col class="flex-grow-0 d-flex align-center">
                      <center class="ma-2">
                        <h4>Key</h4>
                        <ul style="min-width: 275px; text-align: left">
                          <li class="error--text">No Codes Scanned</li>
                          <li class="warning--text">
                            Fewer than {{ scansPerDay }} Scanned
                          </li>
                          <li class="success--text">
                            {{ scansPerDay }} Scanned
                          </li>
                          <li class="info--text">
                            More than {{ scansPerDay }} Scanned
                          </li>
                        </ul>
                      </center>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </tileCard>
          </v-col>
        </v-row>
      </div>
    </transition>

    <transition :name="transitionType" @enter="onEnter">
      <div v-if="view == 'timing'">
        <v-row>
          <v-col cols="12">
            <tileCard :aspect-ratio="0" @click="goTo('root')">
              <template v-slot:content>
                <div class="d-flex justify-center">
                  <v-btn @click="goTo('root')" icon>
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-spacer />
                  <div>
                    <h2>Timing Information</h2>
                  </div>
                  <v-spacer />
                  <v-btn style="opacity: 0" icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </template>
            </tileCard>
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              noClick
              :small="$store.getters.windowSize.width <= 490"
              :content="displayBreakTime(timeSinceLast)"
              :color="mostRecentScanColor"
              >Since Last Scan</tileCard
            >
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              noClick
              :small="$store.getters.windowSize.width <= 490"
              :content="displayBreakTime(timeTillNext)"
              :color="
                timeTillNext.total == 0
                  ? 'success'
                  : timeTillNext.total < 3600
                  ? 'warning'
                  : 'error'
              "
              >Next Scan In</tileCard
            >
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              noClick
              :small="$store.getters.windowSize.width <= 490"
              :content="scansPerDay"
              color="info"
              >Scan{{ scansPerDay != 1 ? "s" : "" }} Per Day</tileCard
            >
          </v-col>
          <v-col cols="6" md="4" lg="3">
            <tileCard
              noClick
              :small="$store.getters.windowSize.width <= 490"
              :content="nextScanDate"
              :color="
                timeTillNext.total == 0
                  ? 'success'
                  : timeTillNext.total < 3600
                  ? 'warning'
                  : 'error'
              "
              >Next Scan Date</tileCard
            >
          </v-col>
        </v-row>
      </div>
    </transition>
    <transition :name="transitionType" @enter="onEnter">
      <div v-if="view == 'none'">
        <v-progress-linear indeterminate label="Calculating..." />
      </div>
    </transition>
  </div>
</template>

<style scoped>
.statsRoot > * {
  max-width: 100%;
  min-width: 100%;
}
</style>

<style>
.smallDatePicker > .v-picker__body {
  min-width: 275px;
  width: auto !important;
}
</style>

<script>
import tileCard from "@/components/tileCard.vue";
export default {
  props: ["dialogStatus"],
  data() {
    return {
      view: "root",
      isMounted: false,
      transitionType: "slide-left",
      forceRefresh: 1,
      refreshTimeout: null,
    };
  },
  computed: {
    dayPercent() {
      let elapsed = 0;
      if (this.forceRefresh) {
        elapsed = this.daysPassed;
      }
      if (elapsed < 0) {
        elapsed = 0;
      }
      let lenDiff = this.endDate.getTime() - this.startDate.getTime();
      let len = Math.round(lenDiff / (24 * 60 * 60 * 1000));
      return this.makePercent(elapsed / len);
    },
    tagPercent() {
      if (this.totalTags) {
        return this.makePercent(this.tagsScanned / this.totalTags);
      } else {
        return this.makePercent(0);
      }
    },
    timeoutManager() {
      let times = [this.timeSinceLast, this.timeTillNext];
      let periods = ["weeks", "days", "hours", "minutes", "seconds"];
      let multipliers = [
        1 * 60 * 60 * 24 * 7,
        1 * 60 * 60 * 24,
        1 * 60 * 60,
        1 * 60,
        1,
      ];
      let shortest = "weeks";
      for (let time of times) {
        for (let index = 0; index < periods.length; index++) {
          let period = periods[index];
          let multiplier = multipliers[index];
          let divide = Math.round(time.total / multiplier);
          if (divide > 1) {
            // return here
            if (periods.indexOf(period) > periods.indexOf(shortest)) {
              shortest = period;
            }
            break;
          }
        }
      }
      // we now have the shortest period we need to wait in "shortest".
      let toWait = multipliers[periods.indexOf(shortest)] * 50;
      let self = this;
      if (self.refreshTimeout !== null) {
        clearTimeout(self.refreshTimeout);
      }
      // eslint-disable-next-line vue/no-async-in-computed-properties
      self.refreshTimeout = setTimeout(() => {
        self.forceRefresh++;
      }, toWait);
      return shortest;
    },
    mostRecentScan() {
      if (this.uncovered.dates.length) {
        return new Date(this.uncovered.dates[this.uncovered.dates.length - 1]);
      } else {
        return [
          {
            mult: 0.6,
            content: "Never",
          },
        ];
      }
    },
    mostRecentScanColor() {
      let mostRecent = this.mostRecentScan;
      if (mostRecent instanceof Date) {
        let yesterday = new Date();
        yesterday = new Date(
          yesterday.getFullYear(),
          yesterday.getMonth(),
          yesterday.getDate() - 1
        );
        let oneWeek = new Date(
          yesterday.getFullYear(),
          yesterday.getMonth(),
          yesterday.getDate() - 6
        );
        if (mostRecent.getTime() > yesterday.getTime()) {
          return "success";
        } else if (mostRecent.getTime() < oneWeek.getTime()) {
          return "error";
        } else {
          return "warning";
        }
      } else {
        let nextDate = this.nextScanDate;
        if (nextDate instanceof Date) {
          return "error";
        } else {
          return "warning";
        }
      }
    },
    timeSinceLast() {
      let timeDiff = 0;
      if (this.forceRefresh) {
        let lastScan = this.mostRecentScan;
        if (lastScan instanceof Date) {
          let now = new Date();
          timeDiff = now.getTime() - lastScan.getTime();
        }
      }
      return this.breakTime(timeDiff);
    },
    nextScanDate() {
      if (this.tagStatusCount < 0) {
        return [
          {
            mult: 0.6,
            content: "Today",
          },
        ];
      } else if (this.tagsScanned == this.totalTags) {
        return [
          {
            mult: 0.6,
            content: "Done",
          },
        ];
      } else {
        let nextDate = new Date();
        nextDate = new Date(
          nextDate.getFullYear(),
          nextDate.getMonth(),
          nextDate.getDate() + 1
        );
        return nextDate;
      }
    },
    timeTillNext() {
      let timeDiff = 0;
      if (this.forceRefresh) {
        let nextDate = this.nextScanDate;
        if (nextDate instanceof Date) {
          let now = new Date();
          timeDiff = nextDate.getTime() - now.getTime();
        }
      }
      return this.breakTime(timeDiff);
    },
    consistencyPercent() {
      let onCount = 0;
      let days = Object.keys(this.scanDateBuckets);
      for (let day of days) {
        let allowed = this.scansPerDay;
        for (let i = 0; i < this.scanDateBuckets[day].length; i++) {
          if (allowed > 0) {
            onCount++;
            allowed--;
          } else {
            break;
          }
        }
      }
      return this.makePercent(
        onCount / (this.tagsScanned - this.tagStatusCount)
      );
    },
    scanDateBuckets() {
      let output = {};
      let indices = Object.keys(this.uncovered.byIndex);
      for (let index of indices) {
        let scanDate = new Date(this.uncovered.byIndex[index]);
        scanDate = new Date(
          scanDate.getFullYear(),
          scanDate.getMonth(),
          scanDate.getDate()
        );
        scanDate = `${scanDate.getFullYear()}-${(
          "0" +
          (scanDate.getMonth() + 1)
        ).slice(-2)}-${("0" + scanDate.getDate()).slice(-2)}`;
        if (!Object.prototype.hasOwnProperty.call(output, scanDate)) {
          output[scanDate] = [];
        }
        output[scanDate].push(index);
      }
      return output;
    },
    totalTags() {
      if (this.$store.getters["awsRegistered/letterLength"] < 0) {
        if (this.$store.getters["awsRegistered/letterLength"] == -2) {
          this.$store.commit("awsRegistered/updateLetterLength", -1);
          this.$store.dispatch("awsRegistered/getLetter");
        }
        return 0;
      } else {
        return this.$store.getters["awsRegistered/letterLength"];
      }
    },
    tagsScanned() {
      return this.uncovered.dates.length;
    },
    tagsRemaining() {
      return this.totalTags - this.tagsScanned;
    },
    scansPerDay() {
      let ud = this.userData;
      if (ud !== true) {
        return ud.scansPerDay;
      } else {
        return 0;
      }
    },
    tagStatusCount() {
      let allowedSoFar = Math.min(
        this.scansPerDay * (this.daysPassed >= 0 ? this.daysPassed + 1 : 0),
        this.totalTags
      );
      return this.tagsScanned - allowedSoFar;
    },
    tagStatusColor() {
      if (!this.tagStatusCount) {
        return "success";
      } else if (this.tagStatusCount + this.scansPerDay >= 0) {
        return "warning";
      } else {
        return "error";
      }
    },
    startDate() {
      let ud = this.userData;
      if (ud !== true) {
        return ud.startDate;
      } else {
        return new Date();
      }
    },
    endDate() {
      let ud = this.userData;
      if (ud !== true) {
        return ud.endDate;
      } else {
        return new Date();
      }
    },
    daysPassed() {
      let now = new Date();
      now = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let firstDate = this.startDate;
      let diff = now.getTime() - firstDate.getTime();
      if (diff > 0) {
        return Math.round(diff / (24 * 60 * 60 * 1000));
      } else {
        return diff / (24 * 60 * 60 * 1000);
      }
    },
    daysRemaining() {
      let lastDate = this.endDate;
      let currentDate = new Date();
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      let diff = lastDate.getTime() - currentDate.getTime();
      return Math.round(diff / (24 * 60 * 60 * 1000));
    },
    userData() {
      let ud = this.$store.getters["awsRegistered/userData"];
      if (ud === false) {
        this.$store.dispatch("awsRegistered/getUserData");
        this.$store.commit("awsRegistered/updateGettingUserData", true);
        return true;
      } else {
        return ud;
      }
    },
    uncovered() {
      let data = this.$store.getters["awsRegistered/uncovered"];
      if (!data.loaded) {
        this.$store.commit("awsRegistered/loadingUncovered");
        this.$store.dispatch("awsRegistered/getUncovered", { need: true });
      }
      return {
        byIndex: data.byIndex,
        dates: data.dates,
      };
    },
    windowWidth() {
      return this.$store.getters.windowSize.width;
    },
  },
  methods: {
    consistencyDatesFunction(date) {
      if (Object.prototype.hasOwnProperty.call(this.scanDateBuckets, date)) {
        let len = this.scanDateBuckets[date].length;
        if (len > this.scansPerDay) {
          return ["info"];
        } else if (len == this.scansPerDay) {
          return ["success"];
        } else {
          return ["warning"];
        }
      } else {
        return ["error"];
      }
    },
    makePercent(val) {
      let percentNum = Math.round(1000 * val) / 10;
      if (percentNum >= 10) {
        percentNum = Math.round(percentNum);
      }
      return percentNum;
    },
    breakTime(timeDiffMs) {
      let seconds = Math.round(timeDiffMs / 1000);
      let minutes = Math.floor(seconds / 60);
      seconds -= minutes * 60;
      let hours = Math.floor(minutes / 60);
      minutes -= hours * 60;
      let days = Math.floor(hours / 24);
      hours -= days * 24;
      let weeks = Math.floor(days / 7);
      days -= weeks * 7;
      return {
        total: Math.round(timeDiffMs / 1000),
        seconds,
        minutes,
        hours,
        days,
        weeks,
      };
    },
    displayBreakTime(breakTime) {
      if (breakTime.total == 0) {
        return "Now";
      }
      let periods = ["Week", "Day", "Hour", "Minute", "Second"];
      let multipliers = [
        1 * 60 * 60 * 24 * 7,
        1 * 60 * 60 * 24,
        1 * 60 * 60,
        1 * 60,
        1,
      ];
      let shortest = "Second";
      for (let period of periods) {
        if (breakTime[`${period.toLowerCase()}s`] > 0) {
          shortest = period;
          break;
        }
      }
      let biggestUnits =
        breakTime.total / multipliers[periods.indexOf(shortest)];
      biggestUnits = Math.round(biggestUnits * 10) / 10;
      return [
        {
          mult: 0.8,
          content: biggestUnits,
        },
        {
          mult: 0.2,
          content: `${shortest}${biggestUnits != 1 ? "s" : ""}`,
        },
      ];
    },
    goTo(where) {
      let self = this;
      if (where == "root") {
        self.transitionType = "slide-right";
      } else {
        self.transitionType = "slide-left";
      }
      self.$nextTick(() => {
        self.view = where;
      });
    },
    onEnter(...args) {
      this.updateHeight(getComputedStyle(args[0]).height);
    },
    updateHeight(height) {
      this.$emit("update:internalHeight", height);
    },
    async handleResize(failCount = 0) {
      if (this.isMounted) {
        let self = this;
        let height = getComputedStyle(self.$refs.rootDiv.parentNode).height;
        if (height == "auto" && failCount < 5) {
          self.$nextTick(self.handleResize.bind(self, failCount + 1));
          return;
        }
        height = height.replace(/[^\d.]/gi, "");
        height = Number(height);
        height -= 24;
        self.updateHeight(`${height}px`);
      }
    },
  },
  components: {
    tileCard,
  },
  async mounted() {
    this.isMounted = true;
    this.handleResize();
  },
  beforeDestroy() {
    if (this.refreshTimeout !== null) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }
  },
  watch: {
    windowWidth() {
      this.handleResize();
    },
    dialogStatus(newVal) {
      if (!newVal && this.view != "root") {
        this.goTo("root");
      }
    },
    timeoutManager() {
      // don't care, just need a function to make sure it's calculated.
    },
  },
};
</script>
